import { linearTransform } from "jubilant-carnival";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetch } from "use-http";

import { ImportanceTrackAnnotation, ImportanceTrackData, ImportanceTrackDataPoint, ImportanceTrackSequence, isImportanceTrackSequence } from "./ImportanceTrack";

type YRange = {
    start: number;
    end: number;
};

export type GenomicRange = {
    chromosome: string;
    start: number;
    end: number;
};

const DATA_QUERY = `
query q($requests: [BigRequest!]!) {
    bigRequests(requests: $requests) {
        data
    }
}
`;

type DataQueryBigResponses = [{
    data: [ string ];
}, {
    data: {
        value: number;
        end: number;
    }[];
}];

type DataQueryResponse = {
    data: {
        bigRequests: DataQueryBigResponses;
    };
};

function genomicRangeToCoordinates(x: GenomicRange) {
    return {
        chr1: x.chromosome,
        start: x.start,
        chr2: x.chromosome,
        end: x.end
    };
}

export function yRange<T>(values: T[], transform: (v: T) => number): YRange {
    const v = values.map(transform);
    const start = Math.min(...v.filter(x => x));
    const end = Math.max(...v.filter(x => x))
    return {
        start: start > 0 ? 0 : start,
        end: end < 0 ? 0 : end
    };
}

export function svgElementTransform(transform: (x: number) => number): (x: number) => [ number, number, string ] {
    return (x: number) => {
        const y1 = transform(x < 0 ? x : x);
        const y2 = transform(x < 0 ? 0 : 0);
        const scale = Math.abs(y2 - y1) / 100;
        return [ y1 * (x < 0 ? -1 : 1), scale, x < 0 ? "scale(1,-1)" : "" ];
    };
}

export function useRenderedImportanceTrackData(data: ImportanceTrackData, height: number): [ ImportanceTrackDataPoint[], (x: number) => [ number, number, string ], (x: number) => number ] {
    const rendered = useMemo( () => 
        isImportanceTrackSequence(data) ? data.importance.map((x, i) => ({
            base: (data as ImportanceTrackSequence).sequence[i],
            importance: x
        })) : data, [ data ]
    );
    const range = useMemo( () => yRange(rendered, x => x.importance), [ rendered ]);
    const rawTransform = linearTransform(range, { start: height, end: 0 });
    const transform = useCallback(svgElementTransform(rawTransform), [ range, height ]);
    return [ rendered, transform, rawTransform ];
};

export function useImportanceTrackData(endpoint: string, signalURLs: string[], sequenceURL: string, coordinates: GenomicRange) {
    const { post, response, loading } = useFetch<DataQueryResponse>(endpoint);
    const [ data, setData ] = useState<DataQueryBigResponses | null | undefined>(null);
    const fCoordinates = genomicRangeToCoordinates(coordinates);
    useEffect( () => {
        post("/graphql", {
            query: DATA_QUERY,
            variables: { requests: [
                { url: sequenceURL, ...fCoordinates },
                ...signalURLs.map(x => ({ url: x, ...fCoordinates }))
            ] }
        }).then(() => setData(response.data?.data.bigRequests))
    }, [ sequenceURL, signalURLs ]);
    return { data, loading };
}

export function useRenderedImportanceTrackAnnotations(annotations: ImportanceTrackAnnotation[], data: ImportanceTrackDataPoint[], transform: (x: number) => number) {
    return useMemo( () => annotations.map( annotation => {
        const points = data.slice(annotation.coordinates[0], annotation.coordinates[1] + 1).map(x => x.importance);
        const range = [ Math.min(...points), Math.max(...points) ];
        const t = transform(range[1]);
        return {
            ...annotation,
            y: t,
            height: transform(range[0]) - t
        };
    }), [ annotations, transform ]);
}
