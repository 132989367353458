import React, { useRef, useMemo, useEffect, useContext } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { ClipPath } from '../../clippath';

import { xtransform, groupFeatures } from '../../../utils/coordinates';
import { renderTranscript, sortedTranscripts } from './utils';
import { bestFontSize } from '../wrapped/margin';
import { PackTranscriptTrackProps, TranscriptRow, Transcript } from './types';
import { TooltipContext } from "../../tooltip/tooltipcontext";
import { svgPoint } from '../../../utils/svg';

/**
 * Renders a transcript track in pack mode. Transcripts are packed to occupy the minimum
 * number of rows possible without overlap.
 */
export const PackTranscriptTrack: React.FC<PackTranscriptTrackProps> = props => {
    const tooltipContext = useContext(TooltipContext);
    const mouseOver = (event:  React.MouseEvent<SVGPathElement>, transcript: Transcript) => {  
        if(props.svgRef)
        {
            let mousePosition = svgPoint(props.svgRef.current!,event)      
            tooltipContext.setTooltip({
                show:true,
                x: mousePosition[0] > props.width - 150 ?  (mousePosition[0] - 150) +5 :  mousePosition[0]+5,  
                y: mousePosition[1],
                width: 150,
                height: 50,
                content: <span style={{fontSize:'10px'}}>{transcript.id}</span>
            });
        }        
    };
    
    const mouseOut = () => {
        tooltipContext.setTooltip({ show: false, x: 0, y: 0, content: undefined, width:0, height: 0 });
    };
    const uuid = useRef(uuidv1());
    const x = xtransform(props.domain, props.width);
    const color = props.color || "#000000";
    const fontSize = bestFontSize(props.rowHeight) * 1.25;

    const rendered: TranscriptRow[] = useMemo(
        () => groupFeatures(sortedTranscripts(props.data || [], props.domain), x, fontSize).map(
            (group, i) => ({
                y: i * props.rowHeight,
                transcripts: group.map(
                    transcript => renderTranscript(transcript, x, props.domain, props.rowHeight, props.width)
                )
            })
        ), [ props.data, props.rowHeight, props.width, props.domain ]
    );

    const height = useMemo( () => props.rowHeight * rendered.length, [ props.rowHeight, rendered.length ] );
    useEffect( () => {
        props.onHeightChanged && props.onHeightChanged(height);
    }, [ height ]);
    return (
        <g transform={props.transform}>
	        <defs>
		        <ClipPath id={uuid.current} width={props.width} height={rendered.length * props.rowHeight} />
            </defs>
            { rendered.map( (group, k) => (
                <g
                    style={{ clipPath: `url(#${uuid.current})` }}
                    transform={`translate(0,${group.y})`}
                    height={props.rowHeight} width={props.width} key={`row_${k}`}
                >
                    { group.transcripts.map( (transcript, j) => (
                        <React.Fragment key={`transcript_${j}`}>
			                <path
                                stroke={transcript.transcript.color || color}
                                fill={transcript.transcript.color || color}
                                strokeWidth={props.rowHeight / 16}
                                d={transcript.paths.introns + transcript.paths.exons}
                                onMouseOver={(e: React.MouseEvent<SVGPathElement>)=> mouseOver(e, transcript.transcript)}
                                onMouseOut={mouseOut}
                                onClick={ () => props.onTranscriptClick && props.onTranscriptClick(transcript.transcript) }
                            />
                            <text
                                fill={transcript.transcript.color || color}
                                fontSize={fontSize}
                                x={transcript.transcript.coordinates.end + 5}
                                y={props.rowHeight / 2}
                                dominantBaseline="middle"
                                style={{ pointerEvents: 'none', WebkitTouchCallout: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'}}            
                            >
			                    {transcript.transcript.name}
			                </text>
                        </React.Fragment>
                    )) }
                </g>
            )) }
        </g>
    );
    
};
export default PackTranscriptTrack;
