import React, { useMemo } from 'react';
import { A, C, G, T } from 'logots-react';

import { ImportanceTrackDataPoint } from './ImportanceTrack';

const COMPONENT_MAP = new Map([
    [ 'A', A ], [ 'C', C ], [ 'G', G ], [ 'T', T ]
]);

const COLOR_MAP = new Map([
    [ 'A', '#880000' ], [ 'C', '#000088' ], [ 'G', '#a89132' ], [ 'T', '#008800' ]
]);

export type LetterProps = ImportanceTrackDataPoint & {
    fill?: string;
    x: number;
    totalHeight: number;
    totalWidth: number;
    transform: (x: number) => [ number, number, string ];
    xScale: number;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onMouseUp?: () => void;
    onClick?: () => void;
};

const Letter: React.FC<LetterProps> = props => {
    const [ y, scale, s ] = useMemo(() => props.transform(props.importance), [ props.transform, props.importance ]);
    return COMPONENT_MAP.get(props.base) ? (
        <>
            <rect
                fill="#000000"
                fillOpacity={0}
                y={0}
                x={props.x}
                width={props.totalWidth}
                height={props.totalHeight}
                onMouseOver={props.onMouseOver}
                onMouseOut={props.onMouseOut}
                onMouseDown={props.onClick}
                onMouseUp={props.onMouseUp}
            />
            <g transform={`${s} translate(${props.x},${y}) scale(${props.xScale},${scale})`}>
                { React.createElement(COMPONENT_MAP.get(props.base)!, {
                    fill: COLOR_MAP.get(props.base)
                }) }
            </g>
        </>
    ) : null;
};
export default Letter;
