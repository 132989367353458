import React, { useEffect } from 'react';

import { EmptyTrackProps } from './types';

const EmptyTrack: React.FC<EmptyTrackProps> = props => {
    useEffect(() => {
        props.onHeightChanged && props.onHeightChanged(props.height);
    }, [ props.height ]);
    return (
        <g className={props.className} width={props.width} height={props.height} transform={props.transform}>
            {props.height!=0 && <rect width={props.width} height={props.height} fill="none" onClick={props.onClick} />}
            {props.text && props.height!=0 && <text textAnchor="middle" x={Math.round(+props.width/2)} y={Math.round(+props.height/2)} style={{ pointerEvents:'none', WebkitTouchCallout: 'none', WebkitUserSelect:'none',MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'}}>{props.text}</text>}
        </g>
    );
};
export default EmptyTrack;
